import React from 'react';
import './Footer.css'; // Ensure this path is correct

const Footer = () => {
    return (
        <footer className="container-fluid text-light pt-3 app-footer">
            <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md text-center text-md-left">
                    <a href="https://jvcspaintingparties.xyz/" target="_blank" rel="noopener noreferrer">
                    <img src="/whiteLogo.PNG" className="img-fluid w-50" alt="JVC's Logo" />
                    </a>
                </div>
                <div className="col-12 col-md mt-4 mt-md-0">
                    <p className="text-center text-md-left">
                        <strong>
                            Web Portfolio
                        </strong>
                    </p>
                    <div className="d-flex justify-content-lg-around justify-content-center pb-4">
                        <a href="https://instagram.com/j_valentine_c" target="_blank" rel="noopener noreferrer"><i className="h1 bi bi-instagram text-light mx-1"></i></a>
                        <a href="https://github.com/JValentineC" target="_blank" rel="noopener noreferrer"><i class="h1 bi bi-github text-light mx-1"></i></a>
                        <a href="https://www.linkedin.com/in/jonathan-ramirez-471203205/" target="_blank" rel="noopener noreferrer"><i class="h1 bi bi-linkedin text-light mx-1"></i></a>
                        <a href="https://www.facebook.com/jonathanvalentinchavez/" target="_blank" rel="noopener noreferrer"><i className="h1 bi bi-facebook text-light mx-1"></i></a>
                    </div>
                    <p className="text-center text-md-left">
                        Feel free to reach out if you want to connect!
                    </p>
                    <p className="text-center phone text-md-left">
                        jvcs.web.designs@gmail.com
                        <br />
                        <code className='phone'>(872) 205-9262</code>
                    </p>

                    <p className="text-center text-md-left">
                        © 2018 JVC. All rights reserved.
                    </p>
                </div>
            </div> 
        </footer>
    );
};

export default Footer;
