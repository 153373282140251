import React from 'react';
import './PaintingPage.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const PaintingPage = () => {
  return (
    <div className='app-Display' style={{ textAlign: 'center', margin: '20px' }}>
      <h1>My Painting Parties Website</h1>
      <iframe
        src="https://jvcspaintingparties.xyz/"
        className='app-iframe'
        title="JVC's Painting Parties"
      ></iframe>
      <div className='description-container' style={{ marginTop: '20px' }}>
        <p>This is the first website I created from scratch. Here are the technologies I used:</p>
        <ul style={{ listStyleType: 'disc', textAlign: 'left', display: 'inline-block' }}>
          <li>HTML</li>
          <li>CSS5</li>
          <li>JavaScript</li>
          <li>Bootstrap 5</li>
          <li>Mailchimp</li>
          <li>Google Forms</li>
          <li>Eventbrite</li>
          <li>Matomo</li>
        </ul>
        <br />
        <a href="https://jvcspaintingparties.xyz/" className="btn btn-dark" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', border: 'none' }} target="_blank" rel="noopener noreferrer">
          Visit the Website
        </a>
      </div>
    </div>
  );
};

export default PaintingPage;
