import React from 'react';
import logo from "../JVC_Logo.png";
import Footer from "./footer";

function Home() {
  return <div>
  <header className="App-header">
  <img src={logo} className="App-logo" alt="logo" />
  <p className="content">
    Welcome to my portfolio! <br />
    I'm JVC, a passionate Developer with a love for Art.<br />
    Here, you'll find a showcase of my projects, skills, and experiences.<br />
    Feel free to explore and reach out if you'd like to connect or collaborate!
  </p>

</header>
<Footer />
</div>
}

export default Home;