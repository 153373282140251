import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import PaintingPage from "./components/PaintingPage";

import "@fortawesome/fontawesome-free/css/all.min.css";

const pages = ["/", "/paintingpage"];

//  Add these after i finish adding content to these pages "/about", "/projects", "/contact",

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

function MainApp() {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    const nextPage = (currentPage + 1) % pages.length;
    setCurrentPage(nextPage);
    navigate(pages[nextPage]);
  };

  const handlePrev = () => {
    const prevPage = (currentPage - 1 + pages.length) % pages.length;
    setCurrentPage(prevPage);
    navigate(pages[prevPage]);
  };

  return (
    <div className="App">
      <i className="fas fa-light fa-arrow-rotate-left" onClick={handlePrev}></i>
      <i className="fas fa-light fa-arrow-rotate-right" onClick={handleNext}></i>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <PageWrapper>
                <Home />
              </PageWrapper>
            }
          />
          <Route
            path="/about"
            element={
              <PageWrapper>
                <About />
              </PageWrapper>
            }
          />
          <Route
            path="/projects"
            element={
              <PageWrapper>
                <Projects />
              </PageWrapper>
            }
          />
          <Route
            path="/contact"
            element={
              <PageWrapper>
                <Contact />
              </PageWrapper>
            }
          />
          <Route
            path="/paintingpage"
            element={
              <PageWrapper>
                <PaintingPage />
              </PageWrapper>
            }
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

function PageWrapper({ children }) {
  return (
    <motion.div
      initial={{ rotateY: 90 }}
      animate={{ rotateY: 0 }}
      exit={{ rotateY: -90 }}
      transition={{ duration: 0.75 }}
      style={{ perspective: 1000 }}
    >
      {children}
    </motion.div>
  );
}

export default App;
